<template>
  <div
    style="margin-top: 2mm; display: flex; overflow: hidden; flex-direction: column; width: 100%; flex: 1;">
    <div :style="styleActionsButtons">
      <i class="s-icon mdi mdi-plus mdi-18px" @click="addGrupo"
        style="cursor: pointer;"></i>
      <input class="s-card" type="text" v-model="inputGrupo"
        @keyup.enter="addGrupo" @keydown.enter.prevent
        placeholder="Adicionar grupo..." />
      <i class="s-icon mdi mdi-eraser mdi-18px" @click="clear()"
        style="cursor: pointer;"></i>
    </div>
    <div
      style="border: 1px solid #D3D3D3; border-radius: 8px; overflow: auto; display: flex; flex-direction: column; flex: 1;">
      <div v-if="!check.length">
        <div style="display: flex; justify-content: center; padding-top: 10%;">
          <label>Esta checklist não tem Check Interativo cadastrado.<br>
            <a :href="`${basePath}/help/Dicas_de_uso/Utilidades/Check_Interativo.html`"
              target="blank"> -> O que é Check Interativo?</a></label>
        </div>
      </div>
      <ul v-else style="list-style-type: none; margin-left: -39px;">
        <li v-for="grupo in check" :key="grupo.id">
          <s-list-grupo :grupo=grupo @remover="removerGrupo"></s-list-grupo>
          <ul style="list-style-type: none;">
            <li v-for="item in grupo.items" :key="item.id">
              <s-list-check :itemProp=item
                @remover="removerProp($event, grupo)"></s-list-check>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SListGrupo from "./SListGrupo.vue";
import SListCheck from "./SListCheck.vue";

export default {
  name: "s-smartcheck",

  inject: ["hdl"],

  components: {
    SListGrupo,
    SListCheck,
  },

  data() {
    return {
      styleActionsButtons: this.editavel
        ? "display: flex; flex-direction: row-reverse;"
        : "display: none;",
      inputGrupo: "",
      showInput: false,
      basePath: Siggma.basePath,
    };
  },

  props: {
    check: {
      type: Array,
      required: true,
    },
    editavel: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    addGrupo() {
      if (this.inputGrupo != "") {
        this.check.push({
          "id": `uuid${Math.uuid(8)}`,
          "name": this.inputGrupo,
          "items": [],
        });
      }

      this.inputGrupo = "";
    },

    removerProp(id, grupo) {
      const index = grupo.items.findIndex((i) => i.id === id);
      grupo.items.splice(index, 1);
    },

    removerGrupo(id) {
      const index = this.check.findIndex((g) => g.id === id);
      this.check.splice(index, 1);
    },

    clear() {
      this.check = [];
    },
  },
};
</script>