<template>
  <div class="s-card">
    <i class="mdi mdi-plus-circle-outline" @click="showInput = !showInput"
      style="cursor: pointer;"></i>
    <label>{{ grupo.name }}</label>
    <i class="mdi mdi-backspace" style="float: right; cursor: pointer;"
      @click="remover"></i>
    <input :style="`visibility: ${showInput ? 'visible' : 'hidden'};`"
      ref="input" class="s-elevation-1" style="margin-top: 5px;" type="text"
      v-model="input" @keyup.enter="addProp" @keypress.enter.prevent
      @blur="showInput = false" />
    <i :style="`visibility: ${showInput ? 'visible' : 'hidden'}; cursor: pointer;`"
      class="mdi mdi-arrow-down-right-bold" @click="addProp"></i>
  </div>
</template>

<script>
export default {
  name: "s-list-grupo",

  data() {
    return {
      showInput: false,
      input: "",
    };
  },

  watch: {
    showInput: function () {
      if (this.showInput) {
        this.$nextTick(() => this.$refs.input.focus());
        return;
      }

      if (this.input != "") {
        this.addProp();
      }
    },
  },

  props: {
    grupo: Object,
    default: () => {},
  },

  methods: {
    addProp() {
      if (this.input != "") {
        this.grupo.items.push({
          "id": `uuid${Math.uuid(8)}`,
          "name": this.input,
          "value": false,
        });
      }

      this.input = "";
      this.showInput = false;
    },

    remover() {
      this.$emit("remover", this.grupo.id);
    },
  },
};
</script>