<template>
  <div class="conteiner">
    <s-onoff v-model="item.value" @click="item.value = !item.value"></s-onoff>
    <label class="s-card-line"
      :style="`text-decoration: ${item.value ? 'line-through' : 'none'}; flex: 1;`">{{ item.name }}</label>
    <i class="mdi mdi-backspace-outline" @click="remove"
      style="cursor: pointer;"></i>
  </div>
</template>

<script>
export default {
  name: "s-list-check",

  data() {
    return {
      item: this.itemProp,
    };
  },

  props: {
    itemProp: Object,
    default: () => {},
  },

  methods: {
    remove() {
      this.$emit("remover", this.item.id);
    },
  },
};
</script>

<style>
.conteiner {
  margin-top: 18px;
  margin-bottom: 20px;
  margin-right: 18px;
  display: flex;
  align-items: center;
}
</style>