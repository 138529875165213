<template>
  <div style="display: flex; position: relative;">
    <div class="container">
      <select style="width: 65px" v-model="value.type" @change="deletar">
        <option value="a1">A1</option>
        <option value="a3">A3</option>
      </select>
      <div style="display: flex; align-items: center;" ref="container">
        <div class="loader" ref="loader">
          <i @click="openInput" :style="`color: ${backgroundColor};`"
            :class="`mdi ${icon} mdi-24px`"></i>
          <input v-if="showSenha" v-model="value.password" placeholder="Senha"
            :readonly="this.certificadoIsValid" @blur="passwordChange"
            class="inputSenha" ref="senha" type="password"
            style="margin-left: 10px;">
          <span v-if="idCertificadoA3"
            style="margin-left: 10px; margin-right: 10px;">
            <label>
              <b>id</b>: {{ idCertificadoA3.substr(0, 20) + "..." }}
            </label>
            <i class="mdi mdi-content-copy mdi-14px" @click="copyToTransfer"
              title="Copiar">
            </i>
          </span>
          <label v-if="this.value.validity"
            style="width: 60px; text-align: center; font-size: 9px; color: black; margin-left: 8px;">
            <span :style="`color: ${certificadoIsValid ? 'black' : 'red'}`">
              {{ certificadoIsValid ? 'Validade' : 'Vencido' }}
            </span>
            <br> {{ validadeFormated }}
          </label>
        </div>
        <i class="mdi mdi-download-circle-outline mdi-24px mdi-action-buttons"
          v-if="showSenha && value.type === 'a1'" style="margin-left: 4px;"
          @click="download" title="Baixar"></i>
        <i class="mdi mdi-delete-forever-outline mdi-24px mdi-action-buttons"
          v-if="showSenha" @click="deletar" title="Excluir"
          style="margin-left: 4px; color: red;"></i>
      </div>
    </div>
    <input ref="fileInput" accept=".pfx" @change="$event => inputChange($event)"
      style="display: none;" type="file">
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "s-certificado-input",

  inject: ["hdl"],
  props: {
    value: Object,
  },

  data() {
    return {
      file: "",
      certificadoIsValid: null,
      loading: false,
      mounted: true,
    };
  },
  computed: {
    backgroundColor() {
      if (this.certificadoIsValid === null) {
        return "black";
      }
      return this.certificadoIsValid ? "#4BB543" : "red";
    },
    showSenha() {
      return !!this.file || this.value.type === "a3" || !!this.value.password;
    },
    validadeFormated() {
      const validade = moment(this.value.validity).format("DD/MM/YYYY");
      return validade === "Invalid date" ? this.value.validity : validade;
    },
    icon() {
      if (this.loading) {
        return "mdi-loading mdi-spin";
      }
      if (this.certificadoIsValid === null) {
        return this.value.type === "a3" ? "mdi-magnify" : "mdi-paperclip";
      }
      return this.certificadoIsValid
        ? "mdi-check-circle-outline"
        : "mdi-close-circle-outline";
    },
    idCertificadoA3() {
      return this.value.type === "a3" ? this.value.route : "";
    },
  },
  methods: {
    openInput() {
      this.value.type === "a1"
        ? this.$refs.fileInput.click()
        : (!this.value.password
          ? Notify.warning("Informe a senha")
          : this.janelaCertificados());
    },

    inputChange(e) {
      if (e.target.files.length) {
        if (e.target.files[0].type !== "application/x-pkcs12") {
          Notify.warning("Formato do certificado inválido");
          this.file = null;
          return;
        }
        this.file = e.target.files[0];
        this.$nextTick(() => this.$refs.senha?.focus());
      }
    },

    copyToTransfer() {
      globalThis.navigator?.clipboard?.writeText(this.value.route).then(
        () => globalThis.Notify.success("ID copiado!"),
        () => globalThis.Notify.warning("Erro ao copiar ID"),
      ).catch(() => globalThis.Notify.warning("Erro ao copiar ID"));
    },

    passwordChange() {
      if (this.$refs?.senha?.readOnly) {
        return;
      }

      if (!this.value.password) {
        Notify.warning("Informe a senha");
        this.$refs.fileInput.data = "";
        return;
      }
      this.certificadoIsValid = null;
      this.value.validity = null;
      if (this.value.type === "a3") {
        this.janelaCertificados();
        return;
      }
      this.loading = true;
      const config = {
        route: this.file,
        senha: this.value.password,
        save: true,
      };
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${Siggma.basePath}/config/configuracoes-documentos-fiscais/testar-certificado-a1`,
      );
      xhr.onload = () => {
        const resp = JSON.parse(xhr.responseText);
        this.loading = false;
        if (resp.type !== "success") {
          this.certificadoIsValid = false;
          Notify.warning(resp.msg);
          return;
        }
        this.value.validity = resp.data.validade;
        this.validVerify();
        if (resp.data.route && this.certificadoIsValid) {
          this.value.route = resp.data.route;
          this.value.updated = true;
        }
      };

      const data = new FormData();
      data.append("certificadoA1", this.file);
      data.append("config", JSON.stringify(config));
      xhr.send(data);
    },

    validVerify() {
      this.certificadoIsValid = !moment(this.value.validity).isBefore(moment());
    },

    deletar() {
      this.value.validity = null;
      this.$refs.fileInput.value = "";
      this.value.password = "";
      this.file = null;
      this.certificadoIsValid = null;
      this.value.route = null;
      this.value.updated = true;
    },

    setA3Props(cerDados) {
      this.value.validity = cerDados.expires;
      this.validVerify();
      this.value.route = cerDados.id;
      this.value.type = "a3";
      this.value.updated = true;
    },

    download() {
      if (!(this.value?.route?.substr(0, 9) === "empresas/")) {
        globalThis.Notify.warning("Download indisponível");
        return;
      }

      const link = globalThis.document.createElement("a");
      link.download = "certificado";
      link.href = `${Siggma.basePath}/data-files/${this.value.route}` +
        "?filename=certificado.pfx&contentType=application/force-download";
      link.click();
    },

    janelaCertificados() {
      const setDados = this.setA3Props;
      const config = {
        senha: this.value.password,
        tipo: "A3",
        tockenConfig: "",
        url: "",
        certId: this.value.route,
      };

      const hdl = Yanda.newJanela({
        isResponsive: true,
        title: "Certificados",
        btMi: true,
        btFe: true,
        dad: this.hdl,
      });
      hdl.setContent(
        `<div id="body" style="display:flex;flex-direction:column;overflow:hidden;height:100%">
          <div>
            <input style="margin: 5px; width: calc(100% - 10px);" v-model="procurar" placeholder="Procurar">
            <i :class="procurar ? 'mdi mdi-close mdi-18px' : 'mdi mdi-magnify mdi-18px'"
              style="top: 8px; right: 11px; position: absolute;"
              @click="procurar && (procurar = '')"></i>
          </div>
          <div style="flex:1;overflow:auto;position:relative" ref="lista" class="s-card">
            <div
              v-for="c in certs"
              @click="selecionar(c)"
              class="s-box-line"
              style="cursor:pointer; height: auto; flex-direction: column; align-items: flex-start;"
            >
              <label style="cursor:pointer;">{{ c.alias }}</label>
              <label style="cursor:pointer;">
                <b>issuer:</b> {{ c.issuer }} <b>expires:</b> {{ c.expires }}
              </label>
              <label style="cursor:pointer;">
                <small><b>Id</b>: {{ c.id }}</small>
              </label>
            </div>
          </div>
          <div id="footer" style="text-align: right;">
              <button @click="fechar">Cancelar</button>
          </div>
        </div>`,
      );
      hdl.setTamanho(600, 500);
      hdl.centraliza();

      hdl.vue = new Vue({
        el: hdl.$("body"),
        data: {
          procurar: "",
          certs: {},
        },
        watch: {
          procurar() {
            this.filtrar(this.procurar);
          },
        },
        methods: {
          fechar() {
            hdl.close();
          },

          selecionar(c) {
            setDados(c);
            this.fechar();
          },

          filtrar(val) {
            const $jq = jQuery(this.$refs.lista);
            $jq.unhighlight({ element: "zjq-tree-highlight" });
            $jq.highlight(val, { element: "zjq-tree-highlight" });

            const primeiro = this.$refs.lista
              .querySelector("zjq-tree-highlight");
            if (!primeiro) {
              this.$refs.lista.scrollTop = 0;
              return;
            }

            this.$refs.lista.scrollTop = primeiro.offsetTop - 10;
          },
        },
        async mounted() {
          const { default: extension } = await Siggma.import("extension.js");
          extension.send({ cmd: "get-certificates", data: config })
            .then((resp) => this.certs = resp.data);
        },
      });
      hdl.show();
    },
  },

  watch: {
    "value.validity"() {
      this.value.validity && this.validVerify();
    },
    $props: {
      handler() {
        this.value.validity && this.validVerify();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.container {
  display: flex;
  align-items: center;
}

.mdi-action-buttons:hover {
  border-radius: 100%;
  background-color: darkgray;
  cursor: pointer;
}

.fileName:hover {
  cursor: pointer;
}

.loader {
  border-radius: 50px;
  padding: 3px 8px;
  border: 1px solid darkgray;
  display: flex;
  align-items: center;
  margin-left: 8px
}

.loader:hover {
  cursor: pointer;
}

.inputSenha {
  height: 24px;
  border: 2pt solid darkgray;
  width: 140px;
}

.inputSenha:focus {
  animation: blinker 1s 2;
  animation-delay: 1300ms;
}

@keyframes blinker {
  0% {
    box-shadow: 0 0 11px #242424;
  }

  50% {
    box-shadow: 0 0 22px #000000;
  }

  100% {
    box-shadow: 0 0 11px #242424;
  }
}
</style>